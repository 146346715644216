<template>
  <div id="third_Party_order">
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <a-tabs v-model="activeKey">
        <a-tab-pane :key="1" tab="内容列表">
          <div class="isDisplay">
            <div class="top">
              <a-form layout="inline">
                <a-form-item>
                  <a-button icon="plus" class="margin_right20" type="primary" @click="addClick('1')">添加</a-button>
                  <a-button class="margin_right20" type="primary" @click="switchClick">当前状态：{{ !switchNma ? '否' : '是' }}</a-button>
                </a-form-item>
              </a-form>
            </div>
            <!-- 表格 -->
            <a-table :columns="columns" :data-source="tableData" :pagination="false" :row-key="record => record.id"
              :loading="tableLoading">
              <span slot="enableFlag" slot-scope="enableFlag, record">
                <a-badge v-if="record.enableFlag == 0" status="warning" text="已下架" />
                <a-badge v-else status="success" text="已上架" />
              </span>
              <span slot="operation" slot-scope="operation, record">
                <a class="margin_right10" type="primary" @click="editClick(record)">编辑</a>
              </span>
            </a-table>
          </div>
        </a-tab-pane>
        <a-tab-pane :key="2" tab="入口二维码">
          <div class="box">
            <span>二维码：</span>
            <UpdataImg @avatarfns="shareImageQrFun" :imageUrlNum="1" :imgUrl="shareImageQrArr" :urlSize="500"></UpdataImg>
          </div>
          <span v-if="shareImageShow" style="color: red;">请上传二维码</span>
          <a-button type="primary" class="tijiao" @click="tijiao">提 交</a-button>
        </a-tab-pane>
      </a-tabs>
    </a-spin>
    <router-view />
  </div>
</template>

<script>
import { answerList, activeQr, isShowSwitch, getShowSwitc, getActiveQr } from "@/request/api/appletsManage";
import MyPagination from "@/components/pagination/MyPagination";
import UpdataImg from "@/components/file/updataImg";
import moment from "moment";
import { message } from "ant-design-vue";
export default {
  components: { MyPagination, UpdataImg },
  created () {
    this.getInitFn()
    this.getShowSwitcInit()
    this.getActiveQrInit()
  },

  data () {
    return {
      tip: "",
      spinning: false,
      activeKey: 1,
      tableLoading: false,
      shareImageShow: false,
      switchNma: 0,
      shareImageQrArr: [],
      columns: [
        {
          title: "名称",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "视频链接",
          dataIndex: "fileUrl",
          key: "fileUrl",
        },
        {
          title: "日期",
          dataIndex: "effectiveDate",
          key: "effectiveDate",
          width: "120px"
        },
        {
          title: "操作",
          width: "70px",
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
    };
  },

  methods: {
    moment,
    dateTimeChange (val, dateString) {
      this.form.effectiveDay = dateString
    },

    // 点击新增按钮
    addClick () {
      this.$router.push(`/appletsManage/mzYdtEdit/0`)
    },

    // 编辑编辑按钮
    editClick (record) {
      this.$router.push(`/appletsManage/mzYdtEdit/${record.id}`)
    },

    shareImageQrFun () {
      console.log(this.shareImageQrArr)
    },

    // 原属数据获取
    getInitFn () {
      answerList({
        pageSize: 50,
        pageNo: 1
      }).then(({ code, data }) => {
        this.tableLoading = false
        this.tableData = data;
      });
    },

    tijiao () {
      activeQr({
        qr: this.shareImageQrArr.toString()
      }).then(({ code, data }) => {
        if (code === 200) {
          message.success('保存成功');
        }
      });
    },
    getActiveQrInit () {
      getActiveQr().then(({ code, data }) => {
        this.shareImageQrArr = data.split(',')
      });
    },

    switchClick () {
      let switchStatus = !this.switchNma ? '1' : '0'
      isShowSwitch({
        switch: switchStatus
      }).then(({ code, data }) => {
        this.switchNma = !this.switchNma
      });
    },
    getShowSwitcInit () {
      getShowSwitc().then(({ code, data }) => {
        this.switchNma = data*1
      });
    },
  },
};
</script>

<style lang="less" scoped>
#third_Party_order {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.avatar {
  width: 100px;
}

.box{
  display: flex;
}
.tijiao{
  margin-left: 75px;
  margin-top: 50px;
}
</style>

